<template>
  <div class="hippolyte-container" @click="onInteract">
    <header :class="{ 'hippolyte-mobile_header': true, 'chat_header': isInterview() }">
      <chat-header
        v-if="ready && isInterview()"
        :conciliation="conciliation"
        closable
        selectable
        @back="$router.push({ name: back })"
        @close="onClose">
      </chat-header>
      <logo-name v-else width="200px" class="logo_with_prop"></logo-name>
    </header>
    <footer>
      <app-menu @toggleMenu="toggleNoMenu" :hide="noMenu"/>
    </footer>
    <main>
      <section ref="container" :class="{ 'hippolyte-main_container': true, 'no-menu': noMenu, scroll }">
        <router-view name="main"/>
      </section>
      <section class="hippolyte-side_container">
        <app-sidebar></app-sidebar>
      </section>
    </main>
  </div>
</template>
<script>
import Notify from '@/notify'
import Permission from '@/notify/permission'
import AppMenu from '@/organisms/menu'
import AppSidebar from '@/organisms/sidebar'
import chatHeader from '@/molecules/details/header'
import LogoName from '@/atoms/logo_name'

export default {
  name: 'hippolyte.template.layout',
  components: { AppMenu, AppSidebar, chatHeader, LogoName },
  props: {
    permission: {
      type: Permission
    }
  },
  data () {
    const meta = this.$route.meta
    return {
      ready: false,
      willAskEnable: Notify.support.notif && this.permission.shouldPrompt(),
      waitClick: this.$config.notif?.wait?.click || 0,
      waitDelay: this.$config.notif?.wait?.delay || 0,
      click: 0,
      willAsk: null,
      noMenu: (meta.show && meta.show.menu === false) || false,
      scroll: meta.scroll === true,
      conciliation: []
    }
  },
  watch: {
    $route (to) {
      this.scroll = to.meta.scroll === true
      if (this.$refs.container) {
        this.$refs.container.scrollTop = 0
      }
      this.noMenu = (to.meta.show && to.meta.show.menu === false) || false

    }
  },
  computed: {
    back () {
      return this.$route.matched.find(r => r.meta.back)?.meta.back
    },
  },
  mounted () {
    Promise.all([this.$loader.candidate, this.$loader.chat])
      .then(() => {
        this.$chat.on('update:current', (r) => this.setConciliation(r))
        this.setConciliation(this.$chat.current)
        this.ready = true
      })
  },
  unmounted () {
    this.$chat.off('update:current', (r) => this.setConciliation(r))
  },
  methods: {
    async onClose (conciliation) {
      const room = this.$chat.rooms.find(r => r.conciliation.account.id === conciliation.account.id)
      await this.$chat.close(room, false)
      conciliation = null
      if (this.$chat.current) {
        this.$router.push({
          name: 'interview',
          params: {
            conciliation: this.$chat.current.conciliation.account.id
          }
        })
      } else {
        this.$router.push({ name: 'interviews' })
      }
    },
    isInterview () {
      return this.$route.name === 'interview'
    },
    setConciliation (room) {
      if (room) {
        this.conciliation = room.conciliation
      }
    },
    toggleNoMenu (hide=false) {
      this.noMenu = hide
    },
    async registerNotify () {
      const notify = new Notify(this.$candidate, this.$socket)
      const register = await notify.register()
      notify.save(register)
    },
    onInteract () {
      this.click++
      if (!Notify.support.notif || !this.willAskEnable || this.willAsk || (this.click < this.waitClick)) {
        return
      }
      this.willAsk = setTimeout(async () => {
        this.$toast.success(`
          <form id="notifform">
            <h4 class="notif-title">${this.$t('notify_toast.title')}<h4>
            <div class="notif-container">
              <div class="notif-body">
                <p class="hidden-mobile">${this.$t('notify_toast.body[0]')}</p>
                <p class="hidden-mobile">${this.$t('notify_toast.body[1]')}</p>
                <div class="actions">
                  <button type="submit" class="ok">${this.$t('notify_toast.action_ok')}</button>
                  <button type="submit" class="later" style="min-width:140px">${this.$t('notify_toast.action_later')}</button>
                </div>
              </div>
            </div>
          </form>
        `, {
          duration: false,
          // eslint-disable-next-line
          onClick: (e, toastObject) => {
            e.preventDefault()
            if (e.target.className === 'ok') {
              this.registerNotify()
            }
            if (e.target.className === 'later') {
              this.permission.later()
            }
          }
        })
      }, this.waitDelay)
    }
  }
}
</script>
<style lang="stylus" scoped>
@import '../colors'
@import '../mixins'
.hippolyte-container
  height 100%
  display flex
  flex-direction column
  justify-content flex-start
  @media (min-width $bp-mobile)
    flex-direction row
  footer
    width 100%
    z-index 5
    background #fff
    border-radius 15px 15px 0 0
    @media (max-width $bp-mobile)
      position fixed
      bottom 0
      box-shadow 0 -5px 15px #bdc4d2
    @media (min-width $bp-mobile)
      flex 0 1 0
  .hippolyte-mobile_header
    height 81px
    &.chat_header
      padding 0
    .shift
      padding-left 2em
    &.hide
      display: none
    .logo_with_prop
      width 200px
    @media (min-width $bp-mobile)
      display none
  main
    flex 1 1 auto
    width 100%
    height calc(100% - 2em - 54px)
    @media (min-width $bp-mobile)
      height 100%
    .hippolyte-main_container
      height 100%
      overflow hidden
      &.scroll
        overflow-y auto
      @media (max-width $bp-mobile)
        height calc(100% - 72px)
        &.no-menu
          height 100%
    .hippolyte-side_container
      display none
    @media (min-width $bp-tablet)
      display flex
      flex-direction row
      .hippolyte-main_container
        width calc(100% - 48px)
        height calc(100% - 48px)
        margin 24px
        background $color-bg-grey
        border-radius 25px
      .hippolyte-side_container
        width 40%
    @media (min-width $bp-mobile)
      display flex
      flex-direction row
      .hippolyte-main_container
        width 75%
        max-height 100%
        overflow-y auto
      .hippolyte-side_container
        display block
        width 25%
        margin 24px 0
        padding-right 24px
.later, .ok
    min-width 140px
</style>
<style lang="stylus" scoped>
@import '../colors'
.actions
  text-align right
@media (max-width $bp-mobile)
  .hide
    display none
  .hippolyte-container
    &.no-menu
      padding-bottom 0
</style>
