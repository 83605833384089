<template>
  <div v-if="ready">
    <div>
      <candidate-profile :candidate="$candidate"></candidate-profile>
    </div>
    <h3>{{ $t('offers.title') }}</h3>
    <div class="go_jb">
      <p>{{ $t('offers.no_offers') }}</p>
      <!-- <button class="btn-primary" @click="openJobBoard">{{ $t('offers.go_jb') }}</button> -->
    </div>
  </div>
</template>

<script>
import CandidateProfile from '@/molecules/candidateProfile'

export default {
  name: 'hippolyte.organisms.sidebar',
  components: {
    CandidateProfile
  },
  data () {
    return {
      ready: false
    }
  },
  mounted () {
    this.$loader.candidate.then(() => {
      this.ready = true
    })
  },
  methods: {
    openJobBoard () {
      window.open(this.$config.jobBoardUrl)
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '../colors'
@import '../mixins'
@import '../atoms/font'
h3
  font-size 18px
  font-weight 600
  line-height 18px
  margin-top 40px
  margin-bottom 24px
.go_jb
  border-radius 15px
  border 1px solid rgba(22, 78, 226, 0.15)
  padding 24px 40px
  text-align center
  p
    font-size 16px
    font-weight 500
    line-height 22px
    margin-top 0
</style>
