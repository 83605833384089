<template>
  <div :class="{ 'app_menu': true, 'open': open, 'hide': hide }">
    <div class="change_open" @click="toggleOpen">
      <img class="menu_open" src="/img/menu_open.svg" />
      <img class="menu_close" src="/img/menu_close.svg" />
    </div>
    <router-link :to="{ name: 'interviews'}">
      <logo width="80px" class="logo"></logo>
      <logo-name width="200px" class="logo_with_prop"></logo-name>
    </router-link>
    <ul class="menu_list">
      <li class="menu_item center" :class="{ 'active': ['dashboard'].includes($route.name) }" :title="$t('title.dashboard')">
        <router-link :to="{ name: 'dashboard'}" @click="chatVisible && toggleChat()">
          <span class="menu_picto_container">
            <icon-dashboard class="menu_picto" width="20px" height="20px"></icon-dashboard>
          </span>
          <span class="menu_label">{{ $t('header.dashboard') }}</span>
        </router-link>
      </li>
      <li class="menu_item center" :class="{ 'active': ['interviews', 'interview'].includes($route.name) }" :title="$t('title.messages')">
        <router-link :to="{ name: 'interviews'}" class="chat_item">
          <span class="menu_picto_container">
            <icon-chat class="menu_picto" width="20px" height="20px"></icon-chat>
          </span>
          <span class="menu_label">{{ $t('header.messages') }}</span>
        </router-link>
        <span class="unread" v-if="$chat.unread > 0">{{ $chat.unread }}</span>
      </li>
      <li class="menu_item center" :class="{ 'active': ['apply', 'applications', 'archives', 'offers'].includes($route.name) }" :title="$t('title.application')">
        <router-link :to="{ name: 'applications'}" @click="chatVisible && toggleChat()">
          <span class="menu_picto_container">
            <icon-apply class="menu_picto" width="20px" height="20px"></icon-apply>
          </span>
          <span class="menu_label">{{ $t('header.applications') }}</span>
        </router-link>
      </li>
      <li class="menu_item center" :class="{ 'active': ['settings', 'profile', 'notifications'].includes($route.name) }" :title="$t('title.params')">
        <router-link :to="{ name: 'settings'}" @click="chatVisible && toggleChat()">
          <span class="menu_picto_container">
            <icon-profile class="menu_picto" width="20px" height="20px"></icon-profile>
          </span>
          <span class="menu_label">{{ $t('header.profile') }}</span>
        </router-link>
      </li>
    </ul>
    <empty :class="{show: chatVisible }" v-if="ready"/>
  </div>
  <!-- bouton back -->
  <router-link class="back" :to="{ name: back }" @click="$emit('toggleMenu', false)" v-if="['interviews', 'interview'].includes($route.name)">
    <icon-back width="15px" height="15px"></icon-back>
  </router-link>
  <span class="back disabled" v-else><b>❮</b></span>
</template>
<script>
import Empty from '@/atoms/chat/empty'
import IconApply from '@/atoms/icons/apply'
import IconChat from '@/atoms/icons/chat'
import IconBack from '@/atoms/icons/back'
import IconDashboard from '@/atoms/icons/dashboard'
import IconProfile from '@/atoms/icons/profile'
import Logo from '@/atoms/logo'
import LogoName from '@/atoms/logo_name'

export default {
  name: 'hippolyte.organism.menu',
  components: {
    Empty,
    IconApply,
    IconChat,
    IconBack,
    IconDashboard,
    IconProfile,
    Logo,
    LogoName,
  },
  props: {
    hide: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      open: false,
      chatVisible: false,
      ready: false,
    }
  },
  emits: ['toggleMenu'],
  mounted () {
    this.$loader.chat.then(() => {
      this.ready = true
      this.$chat.on('unread:message', this.$forceUpdate, this)
    })
  },
  unmounted () {
    this.$chat.off('unread:message', this.$forceUpdate, this)
  },
  computed: {
    back () {
      return this.$route.matched.find(r => r.meta.back)?.meta.back
    },
  },
  methods: {
    toggleOpen () {
      this.open = !this.open
    },
    toggleChat () {
      this.chatVisible = !this.chatVisible
    },
  }
}
</script>

<style lang="stylus" scoped>

@import '../colors'
@import '../mixins'
@import '../atoms/font'
.app_menu
  position relative
  &.hide
    @media (max-width $bp-mobile)
      display none
  .change_open
    display none
    @media (min-width $bp-mobile)
      display block
      position absolute
      top -10px
      right -10px
      background $color-azur
      width 25px
      height 25px
      border-radius 100%
      cursor pointer
      .menu_close
        display none
  .logo
    display none
    @media (min-width $bp-mobile)
      display block
      margin 20px
  .logo_with_prop
    display none
  .menu_list
    padding 0
    display flex
    list-style none
    @media (min-width $bp-mobile)
      display block
    .menu_item
      position relative
      width 33%
      @media (min-width $bp-mobile)
        width 100%
        margin-top 10px
        margin-bottom 20px
      .menu_picto_container
        height 40px
      &.active
        .menu_picto_container
          background $color-azur
          border-radius 100%
          display inline-block
          .menu_picto
            fill none
            stroke $color-white
            > *
              fill none
              stroke $color-white
      .menu_picto
        margin 10px
        fill none
        stroke $color-dark-grey
        *
          fill none
          stroke $color-dark-grey
      .menu_label
        display none
      .unread
        font-sm()
        height 10px
        position absolute
        background $color-iceberg
        color $color-white
        font-weight bolder
        text-align center
        min-width 10px
        padding 0.3em
        border-radius calc(12px + 0.3em * 2)
        bottom 0
        line-height 10px
        left 50%
  &.open
    @media (min-width $bp-mobile)
      .change_open
        .menu_open
          display none
        .menu_close
          display block
      .logo
        display none
      .logo_with_prop
        display block
        margin 20px
        max-width 170px
      .menu_list
        a,
        .chat_item_desktop
          display flex
          text-decoration none
          .menu_picto_container
            margin 0 10px
          .menu_label
            display inline
            margin auto 0
            font-family font-title
            color $color-dark-grey
        .active
          .menu_label
            color $color-azur
        .unread
          left 30px
</style>

<style lang="stylus" scoped>
@import '../colors'
@import '../atoms/font'
.back
  font-big()
  text-decoration none
  color #000
  margin 0
  position fixed
  top 30px
  right 20px
  padding 2px 10px
  background $color-azur
  border-radius 100%
  .menu_close
    height 25px
  &.disabled
    opacity 0
  svg
    fill none
    stroke $color-white
  @media (min-width $bp-mobile)
    display none
.hippolyte-atom-rocket
  display none
  @media(min-width $bp-mobile)
    display block
.app_menu
  .room-list_container, .empty-chat
    transition max-height 0.4s linear
    max-height 0
    display none
    &.show
      display flex
      max-height 40vh
      background $color-torrent
    @media (min-width $bp-mobile)
      display none

</style>
