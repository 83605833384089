const messages = {
  en: {
    candidate: 'candidate',
    yes: 'yes',
    no: 'no',
    cancel: 'Cancel',
    save: 'Save',
    rgpd: 'About your data processing',
    login: 'Login',
    logout: 'Logout',
    start_session: 'Open a session',
    send_link: 'A unique link has been sent to you by email to finalize your connection.',
    welcome_on: 'Welcome on',
    welcome_firstname: 'Welcome {firstname}',
    welcome: 'Welcome',
    parameters: {
      notification: 'Activate notifications',
      cv_link: 'Download the CV linked to your profile',
      placeholder: {
        firstname: 'Firstname',
        lastname: 'Lastname',
        mail: 'Email',
        phone: 'Phone',
        zipcode: 'Zip code',
        city: 'City',
        linkedin: 'Linkedin'
      }
    },
    action: {
      apply: 'Apply',
      decline: 'Decline',
      consult: 'Consult'
    },
    header: {
      profile: 'My profile',
      cv: 'My resume',
      messages: 'My messages',
      applications: 'My applications',
      offers:'My job ads',
      archives:'My archives',
      dashboard: 'Dashboard'
    },
    button: {
      progress: 'In progress',
      archived: 'Archived',
      account: 'My account',
      notif: 'My notifications',
      job_ad: 'job ad | job ads',
    },
    title: {
      joinFile: 'Attach files',
      application: 'Applications',
      messages: 'Messages',
      params: 'Settings',
      notification: 'Notifications',
      dashboard: 'Dashboard'
    },
    notify_toast: {
      title: 'Be responsive !',
      body: [
        'Notifications warn you about new job offers or on new messages.',
        'You can update your settings.'
      ],
      action_ok: 'Activate notifications',
      action_later: 'Not now',
    },
    empty: {
      ads:'No job ad for now',
      messages: 'No current discussion.',
      application: {
        archived: 'No archived application',
        current: 'No current application'
      }
    },
    modal: {
      title: 'Close chatroom',
      body: 'Would you like to end the conversation with {firstname} {lastname}',
      action_cancel: 'Cancel',
      action_confirm: 'Close',
      reachable: {
        title: 'You have not confirmed your contact method',
        body: 'Can you be reached by {target} at {value}?',
        body_done: 'A unique link has been sent to you to validate your contact method.',
        body_error: 'An error occurred while sending the link.',
        action_close: 'Close',
        action_cancel: 'Not now',
        action_confirm: 'Resend the link',
        action_redirect: 'Change my contact method',
        target: {
          mail: 'email',
          phone: 'phone'
        }
      },
      avatar: {
        title: 'Edit your photo',
        action_close : 'Close'
      }
    },
    status: {
      created: 'Created',
      suggested: 'New',
      waiting_acceptation: 'Waiting for validation',
      accepted: 'Accepted', // @todo à valider
      sent: 'Sent',
      opened: 'Viewed',
      started: 'Interviewed',
      archived: 'Archived',
      rejected: 'Not retained', //@todo à valider
      discarded: 'Not retained', //@todo à valider
      declined: 'Not retained', //@todo à valider
      met: 'Met',
      recruited: 'Recruited',
    },
    chat: {
      prompt: {
        placeholder: 'Write a message'
      },
      title: 'Discussions'
    },
    applications: {
      title: 'Your applications'
    },
    offers: {
      go_jb: 'Consult our job board',
      no_offers: 'No recommended offers at this time.',
      title: 'Recommended offers'
    },
    dashboard: {
      count_applications: '<span class="strong bigger wide">{count}</span> applications in progress',
      count_messages: '<span class="strong bigger wide">{count}</span> messages',
      hello: 'Hello {firstname}',
      hello_message: 'You have <span class="strong">{count}</span> applications in progress.<br>Follow their progress below.',
      messages: 'messages',
      applications: 'applications in  progress',
      applications_see_all: 'See all',
      load_applications: 'Loading applications in progress...'
    },
    reachable: {
      valid: 'You have successfully validated your contact method, our teams will contact you soon.',
      error : [
        'An error occurred while validating your contact method.',
        'Reload the page or try again later'
      ],
      already_valid: 'You have already validated your contact method',
      button: 'See your ongoing applications'
    },
    settings: {
      title: 'Account'
    },
    survey: {
      screenAi: {
        title: 'Screen AI Satisfaction Survey',
        intro: 'Thank you for applying at {recruiter}! ',
        duration: '(Survey duration: 1 minute)',
        note: 'On a scale from 0 to 10, how likely are you to recommend our recruitment process via phone interview?',
        explanation: '0: No, not at all, 10: Yes, absolutely',
        comment: 'Could you explain your rating in a few words?',
        suggestion: 'Feedback, improvements...',
        send: 'Send',
        thanks: 'Thank you for taking the time to respond to our survey. Your feedback is valuable for improving our recruitment process.'
      }
    }
  },
  fr:{
    candidate: 'candidat',
    yes: 'oui',
    no: 'non',
    cancel: 'Annuler',
    save: 'Enregistrer',
    rgpd: 'Informations sur le traitement de vos données',
    login: 'Se connecter',
    logout: 'Deconnexion',
    start_session: 'Ouvrir une session',
    send_link: 'Un lien unique vous à été transmis par email pour finaliser votre connexion.',
    welcome_on: 'Bienvenue sur',
    welcome_firstname: 'Bienvenue {firstname}',
    welcome: 'Bienvenue',
    parameters: {
      notification: 'Activer les notifications',
      cv_link: 'Télécharger le cv lié à votre profil',
      placeholder: {
        firstname: 'Prénom',
        lastname: 'Nom',
        mail: 'Email',
        phone: 'Téléphone',
        zipcode: 'Code postal',
        city: 'Ville',
        linkedin: 'Linkedin'
      }
    },
    action: {
      apply: 'Postuler',
      decline: 'Decliner',
      consult: 'Consulter'
    },
    header: {
      profile: 'Mon profil',
      cv: 'Mon CV',
      messages: 'Mes discussions',
      applications: 'Mes candidatures',
      offers:'Mes offres d\'emploi',
      archives:'Mes archives',
      dashboard: 'Tableau de bord'
    },
    button: {
      progress: 'En cours',
      archived: 'Archivée | Archivées',
      account: 'Mon compte',
      notif: 'Mes notifications',
      job_ad: 'Offre d\'emploi | Offres d\'emploi',
    },
    title: {
      joinFile: 'Joindre un fichier',
      application: 'Candidatures',
      messages: 'Discussions',
      params: 'Paramètres',
      notification: 'Notifications',
      dashboard: 'Tableau de bord'
    },
    notify_toast: {
      title: 'Soyez réactif !',
      body: [
        'Les notifications vous préviennent d\'un nouveau message ou de l\'avancée de vos candidatures.',
        'Vous pouvez changer vos préférences dans les paramètres.'
      ],
      action_ok: 'Activer les notifications',
      action_later: 'Pas maintenant'
    },
    empty: {
      ads:'Aucune offre pour le moment',
      messages: 'Aucune discussion en cours pour le moment.',
      application: {
        archived: 'Aucune candidature archivée',
        current: 'Aucune candidature en cours'
      }
    },
    modal: {
      title: 'Terminer la discussion',
      body: 'Souhaitez vous terminer la conversation avec {firstname} {lastname} ?',
      action_cancel: 'Annuler',
      action_confirm: 'Terminer',
      reachable: {
        title: 'Vous n\'avez pas confirmé votre moyen de contact',
        body: 'Êtes-vous joignable par {target} au {value} ?',
        body_done: 'Un lien unique vous à été envoyé pour valider votre moyen de contact.',
        body_error: 'Une erreur est survenue lors de l\'envoi du lien.',
        action_close: 'Fermer',
        action_cancel: 'Pas maintenant',
        action_confirm: 'Renvoyer le lien',
        action_redirect: 'Modifier mon moyen de contact',
        target: {
          mail: 'email',
          phone: 'téléphone'
        }
      },
      avatar: {
        title: 'Modifier votre photo',
        action_close : 'Fermer'
      }
    },
    status: {
      created: 'Créé',
      waiting_acceptation: 'Attente de validation',
      accepted: 'Acceptée', // @todo à valider
      suggested: 'Nouvelle',
      sent: 'Transmise',
      opened: 'Consultée',
      started: 'Interview',
      archived: 'Archivée',
      rejected: 'Non retenue',
      discarded: 'Non retenue',
      declined: 'Non retenue',
      met: 'Rencontre',
      recruited: 'Recruté',
      do_not_send: 'Non retenue'
    },
    chat: {
      prompt: {
        placeholder: 'Ecrire un message'
      },
      title: 'Discussions'
    },
    applications: {
      title: 'Vos candidatures'
    },
    offers: {
      go_jb: 'Consulter notre job board',
      no_offers: 'Aucune offre recommandée pour le moment.',
      title: 'Offres recommandées'
    },
    dashboard: {
      count_applications: '<span class="strong bigger wide">{count}</span> candidature en cours | <span class="strong bigger wide">{count}</span> candidatures en cours',
      count_messages: '<span class="strong bigger wide">{count}</span> message | <span class="strong bigger wide">{count}</span> messages',
      hello: 'Bonjour {firstname}',
      hello_message: 'Vous avez <span class="strong">{count}</span> candidatures en cours.<br>Suivez leur état d\'avancement ci-dessous.',
      messages: 'messages',
      applications: 'candidatures en cours',
      applications_see_all: 'Voir tout',
      load_applications: 'Chargement des candidatures en cours...'
    },
    reachable: {
      valid: 'Vous avez bien validé votre moyen de contact, vous allez bientôt être recontacté par nos équipes.',
      error : [
        'Une erreur est survenue lors de la validation de votre moyen de contact.',
        'Rechargez la page ou réessayez ultérieurement'
      ],
      already_valid: 'Vous avez déjà validé votre moyen de contact',
      button: 'Voir vos candidatures en cours'
    },
    settings: {
      title: 'Mon compte'
    },
    survey: {
      screenAi: {
        title: 'Enquête de satisfaction',
        intro: 'Merci d\'avoir postulé chez {recruiter} ! ',
        duration: '(Durée de l\'enquête : 1 minute)',
        note: 'Sur une échelle de 0 à 10, dans quelle mesure recommanderiez-vous notre processus de recrutement par appel téléphonique ? ',
        explanation: '0 : Non, pas du tout, 10 : Oui, tout à fait',
        comment: 'Pourriez-vous expliquer votre note en quelques mots ?',
        suggestion: 'Avis, améliorations...',
        send: 'Envoyer',
        thanks: 'Merci d\'avoir pris le temps de répondre à notre enquête. Vos retours sont précieux pour améliorer notre processus de recrutement.'
      }
    }
  }, 
  it: {
    candidate: 'candidato',
    yes: 'sì',
    no: 'no',
    cancel: 'Annulla',
    save: 'Salva',
    rgpd: 'Informazioni sul trattamento dei tuoi dati',
    login: 'Accedi',
    logout: 'Disconnetti',
    start_session: 'Apri una sessione',
    send_link: 'Un link unico è stato inviato via email per completare la tua connessione.',
    welcome_on: 'Benvenuto su',
    welcome_firstname: 'Benvenuto {firstname}',
    welcome: 'Benvenuto',
    parameters: {
      notification: 'Attiva notifiche',
      cv_link: 'Scarica il CV collegato al tuo profilo',
      placeholder: {
        firstname: 'Nome',
        lastname: 'Cognome',
        mail: 'Email',
        phone: 'Telefono',
        zipcode: 'Codice Postale',
        city: 'Città',
        linkedin: 'Linkedin'
      }
    },
    action: {
      apply: 'Applica',
      decline: 'Rifiuta',
      consult: 'Consulta'
    },
    header: {
      profile: 'Il mio profilo',
      cv: 'Il mio CV',
      messages: 'I miei messaggi',
      applications: 'Le mie candidature',
      offers: 'I miei annunci di lavoro',
      archives: 'I miei archivi',
      dashboard: 'Cruscotto'
    },
    button: {
      progress: 'In corso',
      archived: 'Archiviato',
      account: 'Il mio account',
      notif: 'Le mie notifiche',
      job_ad: 'annuncio di lavoro | annunci di lavoro',
    },
    title: {
      joinFile: 'Allega file',
      application: 'Candidature',
      messages: 'Messaggi',
      params: 'Impostazioni',
      notification: 'Notifiche',
      dashboard: 'Cruscotto'
    },
    notify_toast: {
      title: 'Sii reattivo!',
      body: [
        'Le notifiche ti avvisano di nuove offerte di lavoro o nuovi messaggi.',
        'Puoi aggiornare le tue impostazioni.'
      ],
      action_ok: 'Attiva notifiche',
      action_later: 'Non ora',
    },
    empty: {
      ads: 'Nessun annuncio di lavoro per ora',
      messages: 'Nessuna discussione corrente.',
      application: {
        archived: 'Nessuna candidatura archiviata',
        current: 'Nessuna candidatura corrente'
      }
    },
    modal: {
      title: 'Chiudi chatroom',
      body: 'Vuoi terminare la conversazione con {firstname} {lastname}',
      action_cancel: 'Annulla',
      action_confirm: 'Chiudi',
      reachable: {
        title: 'Non hai confermato il tuo metodo di contatto',
        body: 'Puoi essere raggiunto tramite {target} a {value}?',
        body_done: 'Un link unico è stato inviato per validare il tuo metodo di contatto.',
        body_error: 'Si è verificato un errore durante l\'invio del link.',
        action_close: 'Chiudi',
        action_cancel: 'Non ora',
        action_confirm: 'Rinvia il link',
        action_redirect: 'Cambia il mio metodo di contatto',
        target: {
          mail: 'email',
          phone: 'telefono'
        }
      },
      avatar: {
        title: 'Modifica la tua foto',
        action_close : 'Chiudi'
      }
    },
    status: {
      created: 'Creato',
      suggested: 'Nuovo',
      waiting_acceptation: 'In attesa di validazione',
      accepted: 'Accettato',
      sent: 'Inviato',
      opened: 'Visualizzato',
      started: 'Colloquio effettuato',
      archived: 'Archiviato',
      rejected: 'Non selezionato',
      discarded: 'Non selezionato',
      declined: 'Non selezionato',
      met: 'Incontrato',
      recruited: 'Assunto',
    },
    chat: {
      prompt: {
        placeholder: 'Scrivi un messaggio'
      },
      title: 'Discussioni'
    },
    applications: {
      title: 'Le tue candidature'
    },
    offers: {
      go_jb: 'Consulta la nostra bacheca di lavoro',
      no_offers: 'Nessuna offerta consigliata al momento.',
      title: 'Offerte consigliate'
    },
    dashboard: {
      count_applications: '<span class="strong bigger wide">{count}</span> candidature in corso',
      count_messages: '<span class="strong bigger wide">{count}</span> messaggi',
      hello: 'Ciao {firstname}',
      hello_message: 'Hai <span class="strong">{count}</span> candidature in corso.<br>Segui il loro progresso qui sotto.',
      messages: 'messaggi',
      applications: 'candidature in corso',
      applications_see_all: 'Vedi tutte',
      load_applications: 'Caricamento delle candidature in corso...'
    },
    reachable: {
      valid: 'Hai validato con successo il tuo metodo di contatto, i nostri team ti contatteranno presto.',
      error : [
        'Si è verificato un errore durante la validazione del tuo metodo di contatto.',
        'Ricarica la pagina o riprova più tardi'
      ],
      already_valid: 'Hai già validato il tuo metodo di contatto',
      button: 'Vedi le tue candidature in corso'
    },
    settings: {
      title: 'Account'
    },
    survey: {
      screenAi: {
        title: 'Questionario di soddisfazione Screen AI',
        intro: 'Grazie per aver fatto domanda presso {recruiter}! ',
        duration: '(Durata del sondaggio: 1 minuto)',
        note: 'Su una scala da 0 a 10, quanto è probabile che consiglierebbe il nostro processo di reclutamento tramite intervista telefonica?',
        explanation: '0: No, per niente, 10: Sì, assolutamente',
        comment: 'Potresti spiegare il tuo voto in poche parole?',
        suggestion: 'Commenti, miglioramenti...',
        send: 'Invia',
        thanks: 'Grazie per aver dedicato del tempo a rispondere al nostro sondaggio. I vostri feedback sono preziosi per migliorare il nostro processo di reclutamento.'
      }
    }
  }
}

export default { messages }
