<template>
  <header>
    <div class="recruiter_name">
      <h3>{{ getName() }}</h3>
      <p>{{ conciliation.recruiter.company }}</p>
    </div>
    <icon-close class="close" @click.prevent.stop="confirm = true" width="20px" height="20px" v-if="closable"/>
  </header>
  <modal-confirm v-if="confirm" media class="confirm-modal" @confirm="$emit('close', conciliation)" @close="confirm = false" :account="conciliation.account"></modal-confirm>
</template>

<script>
import Conciliation from '@/entities/conciliation'
import update from '@/entities/update.mixins'
import IconClose from '@/atoms/icons/close'
import ModalConfirm from '@/molecules/chat/confirm'
import { capitalize } from '@/fun'

export default {
  name: 'hippolyte.molecules.details.header',
  props: {
    conciliation: Conciliation,
    closable: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      confirm: false
    }
  },
  provide () {
    return {
      big: true
    }
  },
  components: {
    IconClose,
    ModalConfirm
  },
  mixins: [update],
  emits: ['close', 'select', 'back'],
  methods: {
    getName () {
      return capitalize([this.conciliation.account.firstname, this.conciliation.account.lastname].join(' '))
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '../../colors'
@import '../../mixins'
header
  background $color-light-grey
  width 100%
  padding-top 32px
  padding-left 24px
  padding-right 24px
  .recruiter_name
    p
      margin-top 3px
      color $color-dark-grey
  .close
  .close_panel
    position absolute
    top 30px
    right 20px
    width 25px
    height 25px
    background $color-bg-grey
    padding 0.4em
    border-radius 100%
    background $color-white
    cursor pointer
    svg
      fill $color-dark-grey
    @media (max-width $bp-mobile)
      display none
</style>
