<template>
  <div :class="{avatar: true, small: avatarSmall, big: avatarBig, bigger: avatarBigger}" :style="{ 'background-image': 'url(' + image() + ')' }"></div>
</template>

<script>
import Candidate from '@/entities/candidate'

export default {
  name: 'hippolyte.atom.avatar',
  props: {
    candidate: Candidate
  },
  inject: {
    avatarSmall: {
      default: false
    },
    avatarBig: {
      default: false
    },
    avatarBigger: {
      default: false
    }
  },
  mounted () {
    this.$bus.on('avatar-change', this.$forceUpdate)
  },
  methods: {
    image () {
      if (!this.candidate) {
        return
      }
      if (this.candidate.avatar) {
        return this.candidate.avatar
      }
      if (this.candidate.custom_field_candidat_avatar) {
        return this.candidate.custom_field_candidat_avatar
      }
      return '/images/default_avatar.svg'
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '../colors'
@import '../mixins'
.avatar
  width 40px
  height 40px
  border-radius 100%
  background-repeat no-repeat
  background-size cover
  background-position center
  &.small
    width 40px
    height 40px
  &.big
    width 54px
    height 54px
  &.bigger
    width 120px
    height 120px
</style>
